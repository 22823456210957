import React, {Fragment, useState, Component} from 'react';
import FontAwesome from "../../components/uiStyle/FontAwesome";
import {Link, Route, useParams, useLocation} from "react-router-dom";
import BannerSection from "../../components/BannerSection";
import ReactDOM from 'react-dom';
import ModalVideo from 'react-modal-video'
import PostOnePagination from "../../components/PostOnePagination";
import OurBlogSectionTwo from "../../components/OurBlogSectionTwo";
import BlogComment from "../../components/BlogComment";
import Fullwidth from "../../components/Fullwidth";
import WidgetTab from "../../components/WidgetTab";
import WidgetTrendingNews from "../../components/WidgetTrendingNews";
import NewsLetter from "../../components/NewsLetter";
import MostShareWidget from "../../components/MostShareWidget";
import FollowUs from "../../components/FollowUs";
// images
import single_post1 from "../../doc/img/blog/single_post1.jpg";
import author2 from "../../doc/img/author/author2.png";
import smail1 from "../../doc/img/blog/smail1.jpg";
import big1 from "../../doc/img/blog/big1.jpg";
import banner1 from "../../doc/img/bg/banner1.png";
import banner2 from "../../doc/img/bg/sidebar-1.png";
import ListCategory from "../../components/Listing";
import adsky from "../../doc/img/images/300x600.jpg";
import adlb from "../../doc/img/images/728x90.png";
import adsidebar from "../../doc/img/images/adsidebar.jpg";
import App from '../../doc/img/images/App.png';
import Live from '../../doc/img/images/Live-box.png';
import eden from '../../doc/img/images/eden.jpg';
import Urdu from '../../doc/img/images/urdu-box.png';
import parse from 'html-react-parser';
// import { StickyContainer, Sticky } from 'react-sticky';
import Sticky from 'react-sticky-el';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";


import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
    PinterestIcon
  } from "react-share";


  class PostTwoHTwoPage extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
			isShow: false,
            isLoadingP2: true,
            slug : this.props.match.params.slug,
            yoastData: ""
          }
          
        //we need to bind 'this' using ES6(javascript framework)
        // this.fetchnews = this.fetchnews.bind(this);
      
        
    }


    handleClick(){
    
        // Changing state
        window.location.reload();
      }

      
    componentDidMount()
    {


        fetch('https://arynews.tv/api/posts.php?slug='+this.state.slug).then((resp) => {

            resp.json().then((result) => {
                console.log(result)
                this.setState({singlepost: result.inner_post})


            })
            });
      
     
            

        fetch('https://arynews.tv/wp-json/wp/v2/posts?slug='+this.state.slug).then((resp) => {

            resp.json().then((result) => {
            
                this.setState({yoastData: result[0]});
                this.setState({title: result[0]['title']['rendered']});
                alert(this.state.title);
               
            })
           
            });

        
        // alert(JSON.stringify(this.props ));
        window.scrollTo(0, 0);
//    alert(this.props.match.params.slug);
      // Multimedia


  }

 
    // componentDidMount(){
        
    //     const {handle} = useParams();
    //     const location = useLocation();
        
    //     const {title} = location.state;
    //     const [user, setUser] = React.useState(null);

    //     alert({handle});
    
    // }
    
    

    render() {
      
        const shareUrl = 'https://arynews.tv/'+this.state.slug;
        //alert(this.state.yoastData);
        //const html = ReactHtmlParser (this.state.yoast);
    
        // const renderHTML = (escapedHTML: String) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });
        // <Route path="/Topics/:Occupation/:objectId/:id/:userName" component={Fullwidth} />
        // alert(this.props.location.title);
        return (
           
            <Fragment>
                
                 
          
                <Helmet>
                <title>{this.state.title}</title>
                {parse(`${this.state.yoastData['yoast_head']}`)}
                {/* {parse(this.state.yoastData['yoast_head'])} */}
                </Helmet>
              
                
                <div className="archives layout3 post post1 padding-top-30">
                    <div className="container">
                        {this.state.singlepost ? 
                this.state.singlepost.map((item, i)=>

                
                    <div className="row">

                    
                        <div className="col-md-3 col-lg-3 stick">
                
                            {/* <WidgetTab/> */}
                        <Sticky bottomOffset={80}>
                            <div className="row">
                        
                                <div className="authorbox">
                                    <div className="col-lg-12 align-self-center">
                                   
                                        <div className="author">
                                            <div className="author_img">
                                                <div className="author_img_wrap">
                                                 
                                                     <img src={author2} alt="author2"/>
                                                   
                                                   
                                                </div>
                                            </div>
                                            <Link to="/">{item.author_name}</Link>
                                            
                                            <ul>
                                                <li><Link to="/">{item.pub_date}</Link></li>
                                                {/* <li>Created 1:58 p.m. ET</li> */}
                                            </ul>
                                        </div>
                                  
                                    </div>
                               
                                <div className="col-lg-12 align-self-center">
                                    <div className="author_social inline text-center">
                                    
                                        <ul>
                                            <li><Link to="/"><FontAwesome name="instagram"/></Link></li>
                                            <li><Link to="/"><FontAwesome name="facebook-f"/></Link></li>
                                            <li><Link to="/"><FontAwesome name="youtube-play"/></Link></li>
                                            <li><Link to="/"><FontAwesome name="instagram"/></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                             
                           
                            </div>
                          
                            <div className="space-20"/>
                            
                            <img src={App}/>
                            
                            <div className="space-20"/>
                
                            <center> <img src={adsidebar}/> </center>
                            </Sticky>
                        </div>
                
                        <div className="col-6 col-md-6 col-lg-6 m-auto">
                            {/* <div className="bridcrumb"><Link to="/">Home</Link> / Archive / Audio Post 1</div>
                            <span className="space-30"/> */}
                           
                          
                      
                            <div className="border-radious5">
                                <img src={item.image} alt="thumb"/>
                            </div>
                            <div className="space-20"/>
                            <div className="single_post_heading">
                                <h2>{item.title}</h2>
                            </div>
                            <div className="space-20"/>
                            <div>
                            <FacebookShareButton url={shareUrl}>
                                        <FacebookIcon size={70} />
                                    </FacebookShareButton>
                            <TwitterShareButton url={shareUrl}>
                                        <TwitterIcon size={70} />
                            </TwitterShareButton>

                            <WhatsappShareButton url={shareUrl}>
                                        <WhatsappIcon size={70}/>
                            </WhatsappShareButton>

                            <EmailShareButton url={shareUrl}>
                                        <EmailIcon size={70}/>
                            </EmailShareButton>
                            <PinterestShareButton url={shareUrl}>
                                        <PinterestIcon size={70}/>
                            </PinterestShareButton>
                            </div>
                            {/* <FollowUs title="Follow Us"/> */}
                            {/*ok*/}
                           
                            
                            <div className="space-30"/>
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_comments">
                                        <ul className="inline">
                                            <li className="page_category">{item.categories[0]}</li>
                                            <li><FontAwesome name="comment"/>563</li>
                                            <li><FontAwesome name="fire"/>536</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div  className="space-20"/>
                            <div>
                                 {parse(item.content)}
                             </div>
                         
                             <div className="space-40"/>
                            <div className="border_black"/>
                            <div className="space-40"/>
                            <div className={item.ID+1}>
                            <PostOnePagination category='must-read-2' className="next_prv_single padding20 fourth_bg" load={this.handleClick}/>
                            </div>
                        </div>
                            
                        <div className="col-md-3 col-lg-3 stick">
                            {/* <WidgetTab/> */}
                            <Sticky bottomOffset={400}>    
                            <img src={eden}/>
                            <div className="space-20"/>
                            <ListCategory category="must-read-2" count="6" heading="Latest News"/>
                
                            {/* <WidgetTrendingNews/>
                            <div className="banner2 mb30">
                                <Link to="/">
                                    <img src={banner2} alt="thumb"/>
                                </Link>
                            </div>
                            <MostShareWidget title="Most Share"/> */}
                            <div className="space-20"/>
                            <img src={adsky}/>
                            </Sticky>
                        </div>
                    </div>
                     ) : null }



                </div>
               
        
               
      
               

                
               
                    

                                                
             
                

                            

</div>                                        
            </Fragment>
        )
    }
}


export default PostTwoHTwoPage;