import React, {useState, useEffect } from 'react';
import {NavLink} from "react-router-dom";
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import {Collapse} from "reactstrap";

import './style.scss';

const SidebarMenu = ({menus, sideShow, setSideShow, className}) => {
    const [sMenu, setSMenu] = useState(null);
    const [stMenu, setSTMenu] = useState(null);

    const [nav, setNav] = React.useState(0);
    // const [state, setState] = useState(initialState);
    
    useEffect(() => {
        // const count = 0;
       
        if(nav == 0) 
         fetch('https://videos.arynews.tv/api/newsmenujson.php').then((resp) => {

        resp.json().then((result) => {
          
            setNav(result)
            console.log(result)
          
     
  })

  }) })

    return (
        <div className={`sidebarMenu ${sideShow ? '' : 'hideSideMenu'} ${className ? className : ''}`}>
            <span className="clox" onClick={() => setSideShow(false)}>Close</span>
            <ul className="navBar">
            <li className="nav-item"> <a href="https://arynews.tv/"> Home </a> </li>
            {nav ? nav.map((item, i) => (

                
                                            <li onClick={() => setSideShow(false)} key={i}
                                                className={`
                                                ${item.menu_item_parent ? 'dropdown' : ''}
                                                nav-item`}>
                                                {item.title != 'TV Shows' && item.title != 'Home' ? <Link 
                                                                   to= {{pathname: "/" + item.url.replace("https://videos.arynews.tv/","")}}
                                                                       className=""
                                                                       data-toggle="">{item.title}
                                                        <FontAwesome
                                                            name={item.icon}/></Link>
                                                    : null
                                                    // <NavLink to={item.link} className="menu-dropdown"
                                                    //            data-toggle="dropdown">{item.post_title} <FontAwesome
                                                    //     name={item.icon}/></NavLink>
                                                        }

                                                
                                            </li>
                                        )): null }

                                                        
                                                
            </ul>
        </div>
    );
};

export default SidebarMenu;