import React, {useState} from 'react';
import {Link} from "react-router-dom";
import Swiper from 'react-id-swiper';
import FontAwesome from "../uiStyle/FontAwesome";

const TopBarTwo = () => {
    const [swiper, setSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    };
    return (
        <div className="topbar white_bg" id="top">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 align-self-center">
                        <div className="trancarousel_area">
                            <p className="trand">Trending</p>
                            <div className="trancarousel nav_style1">
                            <div className="trendinmenu">
                                <ul className="inline">
                                    <li><a href="https://arynews.tv/latest-news/">LATEST NEWS</a></li>
                                    <li><a href="https://live.arynews.tv/">LIVE NEWS</a></li>
                                    <li><a href="https://urdu.arynews.tv/">URDU NEWS</a></li>
                                    <li><a href="https://live.arynews.tv/audio/">AUDIO NEWS</a></li>
                                    <li><a href="https://arynews.tv/coronavirus-2/">CORONAVIRUS</a></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                        <div className="top_date_social text-right">
                            <div className="social1">
                                <ul className="inline">
                                    <li><a href="https://twitter.com/ARYNEWSOFFICIAL"><FontAwesome name="twitter"/></a></li>
                                    <li><a href="https://www.facebook.com/arynewsasia/"><FontAwesome name="facebook-f"/></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCMmpLL2ucRHAXbNHiCPyIyg"><FontAwesome name="youtube-play"/></a></li>
                                    <li><a href="https://www.instagram.com/arynewstv/?hl=en"><FontAwesome name="instagram"/></a></li>
                                </ul>
                            </div>
                            
                            {/* <div className="user3"><FontAwesome name="user-circle"/></div>
                            <div className="lang-3"><Link to="#">English <FontAwesome name="angle-down"/></Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBarTwo;