import React, {Component} from 'react';
import tab21 from '../../doc/img/tab/tab21.jpg';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

class CustomWidget extends React.Component {
  

        constructor(props) 
        {
            super(props);
            this.state = {
                isShow: false
              }
            //we need to bind 'this' using ES6(javascript framework)
            // this.fetchnews = this.fetchnews.bind(this);
          
      
          }
        
        componentDidMount()
        {
          // Multimedia
    fetch('https://videos.arynews.tv/api/jsonify.php?cat='+this.props.category+'&count=3&post_type=post&tax=category').then((resp) => {
    
      resp.json().then((result) => {
          console.log(result)
          this.setState({multimedia: result.specific_post})
      })
    
      });
    }
    render(){
    return (
        <div className="opinion white_bg padding20 border-radious5 marginb">
           <div className="row">
                    <div className="col-12 nopaddinglr">
                        <div className="heading padding20 pt0 border-radious5">
                            <h3 className="widget-title margin0">{this.props.heading}</h3>
                        </div>
                    </div>
                </div>
            {this.state.multimedia ?
								this.state.multimedia.slice(0, 1).map((item, i)=>
            <div key={i} className="single_post post_type3 post_type15">
            
                <div className="post_img border-radious5">
                    <Link to= {{pathname: "/" + item.slugs}}>
                        <img src={item.image} width="100%" alt="thumb"/>
                    </Link>
                </div>
                <div className="single_post_text nopaddinglr">
                    <h4><Link className="line-clamp2" to= {{pathname: "/" + item.slugs}}>{item.title}</Link></h4>
                    <div className="space-20"/>
                </div>
                
            </div>
                                 ) : null }
            {this.state.multimedia ?
								this.state.multimedia.slice(1, 3).map((item, i)=>
                                <div key={i} className="col-md-6 col-lg-6 twoLines nopaddingl">
                    <div className="single_post post_type3 xs-mb90 post_type15">
                        <div className="post_img border-radious5">
                            <Link to= {{pathname: "/" + item.slugs}}>
                                <img className="img100" src={item.image} alt="big_img"/>
                            </Link>
                        </div>
                        <div className="single_post_text nopaddinglr">
                            <h4><Link className="line-clamp2 smalltext" to= {{pathname: "/" + item.slugs}}>{item.title}</Link></h4>
                        </div>
                    </div>
                    </div>

                                ): null }

        </div>
    );
};
}
export default CustomWidget;