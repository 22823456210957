import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../../components/uiStyle/FontAwesome";

import trend31 from '../../doc/img/trending/trend31.jpg';
import trend32 from '../../doc/img/trending/trend32.jpg';
import trend33 from '../../doc/img/trending/trend33.jpg';
import trend34 from '../../doc/img/trending/trend34.jpg';
import trend35 from '../../doc/img/trending/trend35.jpg';
import trend36 from '../../doc/img/trending/trend36.jpg';
import trend37 from '../../doc/img/blog/90652.jpg';
import MyLoader from "../../components/Loader";

class ArynewsListing extends React.Component {

    constructor() 
    {
        super();
        this.state = {
			isShow: false,
            isLoadingP2: true
          }
        //we need to bind 'this' using ES6(javascript framework)
        // this.fetchnews = this.fetchnews.bind(this);
      
  
      }
    

    componentDidMount()
      {
        

            fetch('https://arynews.tv/api/jsonify.php?cat=must-read-2&count=5&post_type=post&tax=category').then((resp) => {

                resp.json().then((result) => {
                    console.log(result)
                    this.setState({
                        listcategory: result.specific_post, 
                        isLoadingP2: false,
                        
                    })
                })
            
                });

       

      
    }

render(){
    return (
        
               <div className="popular_items scroll_bar padding20 white_bg mb20 border-radious5">
                    <div className="row">
                    <div className="col-12 nopaddinglr">
                        <div className="heading padding20 pt0 border-radious5">
                            <h3 className="widget-title margin0">{this.props.heading}</h3>
                        </div>
                    </div>
                </div>
                {this.state.isLoadingP2 ? <MyLoader/> :
                    <>
                   {this.state.listcategory ?
								this.state.listcategory.map((item, i)=>
                        <Fragment key={i}>
                                <div className="single_post type10 type16 widgets_small mb15">
                                    <div className="post_img">
                                        <div className="img_wrap">
                                            <a href={item.permalink}><img src={item.image} alt="thumb"/></a>
                                        </div>
                                    </div>
                                    <div className="single_post_text">
                                        {/* <div className="meta3"><Link to="">TECHNOLOGY</Link>
                                            <Link to="">March 26, 2020</Link>
                                        </div> */}
                                        <h4><a className="line-clamp2" href={item.permalink}>{item.title}</a></h4>
                                    </div>
                                </div>
                                {i + 1 < this.props.count ? <Fragment>
                                    <div className="space-5"/>
                                    <div className="border4"/>
                                    <div className="space-15"/>
                                </Fragment> : null}
                            </Fragment>
                        ): null }
                        </>
                        }
                    </div>
                
           
   
    );
};
}
export default ArynewsListing;