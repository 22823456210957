import React, {Component} from 'react';
import {Link} from "react-router-dom";
import TrendingCarousel from "../TrendingCarousel";
import FontAwesome from "../uiStyle/FontAwesome";

import big_img from '../../doc/img/trending/trend22.jpg';

class TrendingNewsTwo extends React.Component {

    constructor() 
    {
        super();
        this.state = {
			isShow: false
          }
        //we need to bind 'this' using ES6(javascript framework)
        // this.fetchnews = this.fetchnews.bind(this);
      
  
      }
    
    componentDidMount()
    {
       
      // Multimedia
fetch('https://arynews.tv/api/jsonify.php?cat=multimedia-stories&count=1&post_type=post&tax=category').then((resp) => {

  resp.json().then((result) => {
      console.log(result)
      this.setState({multimedia: result.specific_post})
  })

  });

  // Top News

  fetch('https://arynews.tv/api/jsonify.php?cat=must-read-2&count=4&post_type=post&tax=category').then((resp) => {

  resp.json().then((result) => {
      console.log(result)
      this.setState({topnews: result.specific_post})
      global.topnews2 = result.specific_post;
  })

  });

 
    }

    render() {

        

    return (
        <div className="white_bg tranding3 padding20 border-radious5 mb30 ">
            <div className="row">
                <div className="col-12">
                    <div className="heading">
                        <h2 className="widget-title">HOT SHOTS</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                    
                    <div className="col-md-12 col-lg-12 nopaddinglr">
                {this.state.topnews ?
								this.state.topnews.map((item, i)=>
                                <div className="col-md-3 col-lg-3 twoLines">
                    <div className="single_post post_type3 xs-mb90 post_type15">
                        <div className="latest post_img border-radious5">
                            <Link to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>
                                <img src={item.image} alt="big_img"/>
                            </Link>
                            <span className="tranding border_tranding"><FontAwesome name='play'/></span>
                        </div>
                        <div className="single_post_text nopaddinglr paddingtb">
                            <h4><Link className="line-clamp2 smalltext" to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>{item.title}</Link></h4>
                        </div>
                    </div>
                    </div>

                                ): null }
                </div>
              
                </div>
                
                <div className="row mt20">
                <div className="col-12">
                    <div className="heading">
                        <h2 className="widget-title">HEADLINES</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                    
                    <div className="col-md-12 col-lg-12 nopaddinglr">
                {this.state.topnews ?
								this.state.topnews.map((item, i)=>
                                <div className="col-md-3 col-lg-3 twoLines">
                    <div className="single_post post_type3 xs-mb90 post_type15">
                        <div className="latest post_img border-radious5">
                            <Link to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>
                                <img src={item.image} alt="big_img"/>
                            </Link>
                            <span className="tranding border_tranding"><FontAwesome name='play'/></span>
                        </div>
                        <div className="single_post_text nopaddinglr paddingtb">
                            <h4><Link className="line-clamp2 smalltext" to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>{item.title}</Link></h4>
                        </div>
                    </div>
                    </div>

                                ): null }
                </div>
              
                </div>

        </div>
    );
};
}
export default TrendingNewsTwo;