import React from 'react';
import {Link} from "react-router-dom";
import FooterCopyright from "../FooterCopyright";
import FollowUs from "../FollowUs";
import NewsLetter from "../NewsLetter";
import FontAwesome from "../uiStyle/FontAwesome";
import WidgetMorenews from "../WidgetMorenews";
import TwitterfeedTwo from "../TwitterfeedTwo";

// images
import logo from '../../doc/img/logo/logo.png';
import banner from '../../doc/img/add/add21.jpg';
import phone_black from '../../doc/img/icon/phone_black.png';
import speaker_black from '../../doc/img/icon/speaker_black.png';
import envelope_black from '../../doc/img/icon/envelope_black.png';

const FooterAreaTwo = () => {
    return (
        <div className="footer footer_area3 white_bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-lg-3">
                        
                        <div className="single_footer_nav mb30">
                            <h3 className="widget-title2">ARY PLATFORMS</h3>
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul>
                                        <li><a href="https://arynews.tv/">ARY NEWS</a></li>
                                        <li><a href="https://arydigital.tv/">ARY DIGITAL</a></li>
                                        <li><a href="http://arymusik.tv/">ARY MUSIK</a></li>
                                        <li><a href="http://aryqtv.tv/">ARY QTV</a></li>
                                        <li><a href="http://www.aryzindagi.tv/">ARY ZINDAGI</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="space-30"/>
                        <div className="border_black"/>
                        <div className="space-30"/>
                        {/* <TwitterfeedTwo/> */}
                    </div>

                    <div className="col-md-3 col-lg-3">
                        
                        <div className="single_footer_nav mb30">
                            <h3 className="widget-title2">CORPORATE</h3>
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul>
                                        <li><a href="https://arynews.tv/feedback/">FEEDBACK</a></li>
                                        <li><a href="https://arynews.tv/contact-us/">CONTACT US</a></li>
                                        <li><a href="https://arynews.tv/about-us/">ABOUT US</a></li>
                                        <li><a href="https://arynews.tv/careers/">CAREER</a></li>
                                        <li><a href="https://arynews.tv/terms&conditions.pdf">TERMS & CONDITIONS</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="space-30"/>
                        <div className="border_black"/>
                        <div className="space-30"/>
                        {/* <TwitterfeedTwo/> */}
                    </div>

              
                    <div className="col-lg-3 col-md-3 col-sm-12">
                   

                   <h3 className="widget-title">ARY NEWS app download</h3>
                   <p>Free sign & download, iOS, Android & Huawei App</p>
                       <div className="row mt10">
                     
                       <div className="social2 logos">
                           <ul className="inline APPdownload">
                           <li>
                                            <a  href="https://play.google.com/store/apps/details?id=com.aryservices.arynews.en"> 
                                            <img src="https://arynews.tv/wp-content/uploads/2021/05/icon-google.png" />
                                              
                                            </a>
                                            </li>
                                            <li>
                                            <a  href="https://apps.apple.com/us/app/ary-news-pro/id1164931041"> 
                                            <img src="https://arynews.tv/wp-content/uploads/2021/05/icon-apple.png" />
                                            </a>
                                            </li>
                                            <li>
                                            <a  href="https://appgallery.huawei.com/#/app/C100071877"> 
                                            <img src="https://arynews.tv/wp-content/uploads/2021/05/icon-huawei.png" />
                                            </a>
                                            </li>
                                
                           </ul>
                       </div>
                       </div>
                       
                   </div>

                    <div className="col-lg-3 col-md-3 col-sm-12">
                   

                    <h3 className="widget-title">ARY NETWORK</h3>
                        <div className="row mt10">
                      
                        <div className="social2 logos">
                            <ul className="inline">
                                <li>
                                <a href="https://arydigital.tv/">
                                <img src="https://arynews.tv/wp-content/uploads/2021/05/digital-logo.gif"/>
                               </a>
                                </li>
                                <li>
                                <a href="https://aryzindagi.tv/">
                                <img src="https://arynews.tv/wp-content/uploads/2021/05/Zindagi.jpg"/>
                               </a>
                                </li>
                                <li>
                                <a href="https://arymusik.tv/">
                                <img src="https://arynews.tv/wp-content/uploads/2021/05/Ary-Musik.jpg"/>
                               </a>
                                </li>
                                <li>
                                <a href="https://arynews.tv/">
                                <img src="https://arynews.tv/wp-content/uploads/2021/05/Ary-news.jpg"/>
                               </a>
                                </li>
                                <li>
                                <a href="https://aryqtv.tv/">
                                <img src="https://arynews.tv/wp-content/uploads/2021/05/ary-qtv.jpg"/>
                               </a>
                                </li>
                                <li>
                                <a href="https://arysports.tv/">
                                <img src="https://arynews.tv/wp-content/uploads/2021/05/Ary-Sports.jpg"/>
                               </a>
                                </li>
                            </ul>
                        </div>
                        </div>
                        
                    </div>
                    
                   
                </div>
            </div>
            <FooterCopyright/>
        </div>
    );
};

export default FooterAreaTwo;