import React, {Fragment, useState} from 'react';
import FontAwesome from "../../components/uiStyle/FontAwesome";
import {Link, useLocation} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";
import BannerSection from "../../components/BannerSection";
import Whatsnew from "../../components/Whatsnew";
import FollowUs from "../../components/FollowUs";
import WidgetTrendingNewsThree from "../../components/WidgetTrendingNewsThree";
import PostOnePagination from "../../components/PostOnePagination";
import OurBlogSectionTwo from "../../components/OurBlogSectionTwo";
import BlogComment from "../../components/BlogComment";
import ModalVideo from 'react-modal-video';
import ListCategory from "../../components/Listing";
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';
import ReactPlayer from "react-player";
import Dailymotion from 'react-dailymotion';
import MyLoader2 from "../../components/Loader2";
import ArynewsListing from "../../components/ArynewsListing";
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
    PinterestIcon
  } from "react-share";

  import {Bling as GPT} from "react-gpt";
  import {StickyShareButtons} from 'sharethis-reactjs';
  import {InlineShareButtons} from 'sharethis-reactjs';

// images
import sd_banner_img from '../../doc/img/bg/sidebar-1.png';
import single_post1 from "../../doc/img/blog/single_post1.jpg";
import author2 from "../../doc/img/author/author2.png";
import smail1 from "../../doc/img/blog/smail1.jpg";
import big1 from "../../doc/img/blog/big1.jpg";
import big2 from "../../doc/img/blog/big2.jpg";
import mrec from "../../doc/img/images/eden.jpg";
import AppDwonload from "../../doc/img/images/App.webp";
import LiveBox from "../../doc/img/images/Live-box.webp";


class VideoPostThreeHTwoPage extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
			isShow: false,
            isLoadingP2: true,
            slug: this.props.match.params.slug,
            currentShareUrl: null,
            //  singlepost: null,
            yoastData: "",
            
          }
        //   this.refresh = this.refresh.bind(this);
          window.scrollTo(0, 0);
          global.isMobile = false;
    }

    
    componentDidMount()
    {
        
            fetch('https://videos.arynews.tv/api/posts.php?slug='+this.state.slug).then((resp) => {

            resp.json().then((result) => {
        
                this.setState({singlepost: result.inner_post,  isLoadingP2: false, currentShareUrl: result.inner_post[0].permalink});
         
            })
            });

            fetch('https://videos.arynews.tv/wp-json/wp/v2/posts?slug='+this.state.slug).then((resp) => {

                resp.json().then((result) => {
                
                    this.setState({yoastData: result[0]});
                    this.setState({title: result[0]['title']['rendered']});
                    // alert(this.state.title);
                   
                })
               
                });

            window.scrollTo(0, 0);

     
    }

    // refresh() {
    //     this.setState({ products: null });
    //     this.forceUpdate();
    //   }

    componentDidUpdate(prevProps, prevState)
    {

        if(this.props.match.params.slug != this.state.slug){
            window.location.reload(false);
           
            window.scrollTo(0, 0);
                // this.setState({slug: this.props.match.params.slug })
            fetch('https://videos.arynews.tv/api/posts.php?slug='+this.props.match.params.slug).then((resp) => {

                resp.json().then((result) => {
            
                    this.setState({
                        
                        singlepost: result.inner_post,
                        slug: this.props.match.params.slug,
                        isLoadingP2: false,
                        currentShareUrl: result.inner_post[0].permalink
                    
                    })
             
                })
                });
            
                fetch('https://videos.arynews.tv/wp-json/wp/v2/posts?slug='+this.props.match.params.slug).then((resp) => {

                resp.json().then((result) => {
                
                    this.setState({yoastData: result[0]});
                    this.setState({title: result[0]['title']['rendered']});
                    // alert(this.state.title);
                   
                })
               
                });

                

             
                
            
        }

        
        // console.log("match ", this.state.slug);
        // if(this.props.match.params.slug !== this.state.slug)
        
        // this.setState({slug: this.props.match.params.slug})

        // fetch('https://videos.arynews.tv/api/posts.php?slug='+this.state.slug).then((resp) => {

        //     resp.json().then((result) => {
        
        //         this.setState({singlepost: result.inner_post})
         
        //     })
        //     });

     
  
  
         
    }
    
    //   componentWillReceiveProps(nextProps) {
    //     if(this.props.match.params.lesson !== nextProps.match.params.slug) {
    //       api.getLesson(nextProps.match.params.slug)
    //         .then((lesson) => {
    //             this.setState({
    //                 singlepost: singlepost[0]
    //           });
    //         });
    //     }
    //   }

    componentWillMount() {
        // alert(window.innerWidth);
        if (window.innerWidth < 720) {
            global.isMobile = true;

        }

      
    }

    render(){
        const shareUrl = 'https://videos.arynews.tv/'+this.props.match.params.slug;

        // const location = useLocation();

        // alert(shareUrl);
        // alert(this.state.title);
        // const [vModal, setvModal] = useState(false);
        // const [videoId, setVideoId] = useState('0r6C3z3TEKw');
    
        // const openModalVideo = (e, id) => {
        //     e.preventDefault();
        //     setVideoId(id);
        //     setvModal(true)
        // };

    return (

        <Fragment>
                <Helmet>
                <title>{this.state.title}</title>
                {parse(`${this.state.yoastData['yoast_head']}`)}
                {/* {parse(this.state.yoastData['yoast_head'])} */}
                </Helmet>
                
                <div className="container">
            <div className="row">
                    <div className="leaderboard col-lg-12">
                    {/* <img src={lb}/> */}

                     {global.isMobile == true ? (
                     <> 
                     <GPT
                        adUnitPath="/67551462/arynews_videos_300x250"
                        slotSize={[300, 250]}
                     />
                     </>
                     ) : <GPT
                     adUnitPath="/67551462/arynews_videos_728x90"
                     slotSize={[728, 90], [468, 60], [970, 90], [970, 250]}
                        />}

                    </div>
                </div>
                </div>


               
                    
                    
                                 
                

            <div className="archives layout3 post post1 padding-top-30">
                <div className="container">
                    
                    
                    <span className="space-30 test"/>
                    {this.state.isLoadingP2 ? <MyLoader2/> :
                    <div>
                    {this.state.singlepost ? 
                this.state.singlepost.map((item, i)=>

                        
                    <div  key={i} className="row">
                        
                        <div className="col-md-6 col-lg-8 col-xs-12 col-sm-12">
                            <div className="shadow6">
                                <div className="padding20 white_bg">
                                   
                                    <div className="single_post_heading">
                                        <h1>{item.title}</h1>
                                    </div>
                                    <div className="space-20"/>
                                </div>
                                <div className="video_img border-radious5">
                                   {/* {item.video_link_dm} */}
                                   {/* {item.video_url1 != null ?  */}
                                   
                                    {/* : */}
                                    {item.video_url1 == '' ?
                                   <ReactPlayer
                                        url= {item.video_url2}
                                        controls
                                        width="100%"
                                        height="420px"
                                        style={{ padding: '20px'}}
                                        playing="true"
                                    /> 
                                    :

                                    <Dailymotion
                                    video= {item.video_url1.replace("http://www.dailymotion.com/embed/video/","")}
                                    uiTheme="light"
                                    width={item.video_url1 == '' ? 0 : "100%"}
                                    height={item.video_url1 == '' ? 0 : "480px"}
                                    uiShowLogo = "false"
                                    className = "dailysingle"
                                    onAdStart = "https://pubads.g.doubleclick.net/gampad/ads?iu=/67551462/ARYNews-Videos_640x480/ARYNews-Videos-Dailymotion_PreRoll&description_url=https%3A%2F%2Fvideos.arynews.tv%2F&tfcd=0&npa=0&sz=176x144%7C300x250%7C320x180%7C352x288%7C400x300%7C640x360%7C640x480%7C854x480%7C1280x720&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
                                    autoplay
                                    subtitles = "false"
                                    />

                                }
                                    {/* } */}

                                    {/* <ReactPlayer
                                        url= {item.video_url2}
                                        controls
                                    /> */}

                                </div>
                                {/* <div className="marginleft">
                            <div className="space-20 "/>
                            <h3 className="widget-title">Share</h3>
                            </div> */}

                            

                            {/* <div className="marginleft">
                            <FacebookShareButton url={shareUrl}>
                                        <FacebookIcon size={40} />
                                    </FacebookShareButton>
                            <TwitterShareButton url={shareUrl}>
                                        <TwitterIcon size={40} />
                            </TwitterShareButton>

                            <WhatsappShareButton url={shareUrl}>
                                        <WhatsappIcon size={40}/>
                            </WhatsappShareButton>

                            <EmailShareButton url={shareUrl}>
                                        <EmailIcon size={40}/>
                            </EmailShareButton>
                            <PinterestShareButton url={shareUrl}>
                                        <PinterestIcon size={40}/>
                            </PinterestShareButton>
                            </div> */}
                  


                                <div className="padding20 white_bg">
                                    <div className="space-20"/>
                                    {parse(item.content)}
                                </div>
                            </div>
                            <div className="space-30"/>
                            <PostOnePagination category={item.categorie_slug[0]} className="next_prv_single padding20 shadow6 next_prv_single3"/>
                        </div>
                        <div className="col-md-6 col-lg-4 col-sm-12">
                            <div className="mrec">
                               <GPT
                                adUnitPath="/67551462/arynews_videos-home_300x250"
                                slotSize={[160, 600], [336, 280], [300, 250]}
                            />
                            
                            </div>
                            <center>
                                    <a  href="https://arynews.tv/mobile-apps/"> 
                                        <img className="mrec" src={AppDwonload}/> 
                                    </a>
                                    
                                    <a  href="https://live.arynews.tv/pk/"> 
                                        <img className="mrec" src={LiveBox}/>
                                        </a>
                                    </center>
                            {/* <WidgetTrendingNewsThree/> */}
                            <ArynewsListing category="must-read-2" count="4" heading="Latest News" />
                            
                            {/* <NewsLetter className="mb-0 news3bg padding20 white_bg border-radious5"/> */}
                        </div>

                        
                    </div>

                    
                    ) : null } 
                        </div>
                    }
                   

                    <StickyShareButtons
                                helloworld={this.state.slug}
                                config={{
                                alignment: 'left',    // alignment of buttons (left, right)
                                color: 'social',      // set the color of buttons (social, white)
                                enabled: true,        // show/hide buttons (true, false)
                                font_size: 16,        // font size for the buttons
                                hide_desktop: false,  // hide buttons on desktop (true, false)
                                labels: 'counts',     // button labels (cta, counts, null)
                                language: 'en',       // which language to use (see LANGUAGES)
                                min_count: 0,         // hide react counts less than min_count (INTEGER)
                                networks: [           // which networks to include (see SHARING NETWORKS)
                                'whatsapp',
                                'facebook',
                                'twitter',
                                'linkedin',
                                'email'
                                ],
                                padding: 12,          // padding within buttons (INTEGER)
                                radius: 4,            // the corner radius on each button (INTEGER)
                                show_total: true,     // show/hide the total share count (true, false)
                                show_mobile: true,    // show/hide the buttons on mobile (true, false)
                                show_toggle: true,    // show/hide the toggle buttons (true, false)
                                size: 48,             // the size of each button (INTEGER)
                                top: 160,             // offset in pixels from the top of the page
                    
                                // OPTIONAL PARAMETERS
                                url: shareUrl // (defaults to current url)
                                // image: 'item.image',  // (defaults to og:image or twitter:image)
                                // description: 'custom text',       // (defaults to og:description or twitter:description)
                                // title: 'custom title',            // (defaults to og:title or twitter:title)
                                // message: 'custom email text',     // (only for email sharing)
                                // subject: 'custom email subject',  // (only for email sharing)
                                // username: 'custom twitter handle' // (only for twitter sharing)
                    
                            }}
                        />

                </div>
            </div>
               
            {/* <div className="space-100"/>
            <OurBlogSectionTwo/>
            <div className="space-100"/>
            <BlogComment/>
            <div className="space-100"/>
            <BannerSection className="section-padding2 theme3_bg layout3"/> */}
            {/* <ModalVideo channel='youtube' isOpen={vModal} videoId={videoId}
                        onClose={() => setvModal(false)}/> */}
        </Fragment>
    )
};
}
export default VideoPostThreeHTwoPage;