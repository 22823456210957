import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1240}
    height={84}
    viewBox="0 0 1240 84"
    backgroundColor="#8a8a8a"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="1240" height="11" /> 
    <rect x="76" y="0" rx="3" ry="3" width="1240" height="11" /> 
    <rect x="127" y="48" rx="3" ry="3" width="1240" height="11" /> 
    <rect x="187" y="48" rx="3" ry="3" width="1240" height="11" /> 
    <rect x="18" y="48" rx="3" ry="3" width="1240" height="11" /> 
    <rect x="0" y="71" rx="3" ry="3" width="1240" height="11" /> 
    <rect x="18" y="23" rx="3" ry="3" width="1240" height="11" /> 
    <rect x="166" y="23" rx="3" ry="3" width="1240" height="11" />
  </ContentLoader>
)

export default MyLoader