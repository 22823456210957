import React, {Fragment, Component} from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import big_img from '../../doc/img/header/sider-top3.jpg';
import col26 from '../../doc/img/header/widget/col26.jpg';
import col21 from '../../doc/img/header/widget/col21.jpg';
import col22 from '../../doc/img/header/widget/col22.jpg';
import col23 from '../../doc/img/header/widget/col23.jpg';
import col24 from '../../doc/img/header/widget/col24.jpg';
import col25 from '../../doc/img/header/widget/col25.jpg';
import App from '../../doc/img/images/App.png';
import Live from '../../doc/img/images/Live-box.png';
import eden from '../../doc/img/images/eden.jpg';
import Urdu from '../../doc/img/images/urdu-box.png';

const posts = [
    {
        image: col21,
        title: 'The city with highest quality of life in world.',
        category: 'TECHNOLOGY',
    },
    {
        image: col22,
        title: 'Fire shows that will improve your…',
        category: 'TECHNOLOGY',
    },
    {
        image: col23,
        title: 'Mutul fund mark from down up to 15%.',
        category: 'TECHNOLOGY',
    },
    {
        image: col24,
        title: 'Danny meyer’s form latest restaurantes…',
        category: 'TECHNOLOGY',
    },
    {
        image: col25,
        title: 'Wright begins in rehab assignment at the..',
        category: 'TECHNOLOGY',
    },
];


class PostGalleryTwo extends React.Component {

    constructor() 
    {
        super();
        this.state = {
			isShow: false
          }
        //we need to bind 'this' using ES6(javascript framework)
        // this.fetchnews = this.fetchnews.bind(this);
      
  
      }
    

    componentDidMount()
      {
        
        // Multimedia
fetch('https://arynews.tv/api/jsonify.php?cat=multimedia-stories&count=1&post_type=post&tax=category').then((resp) => {

    resp.json().then((result) => {
        console.log(result)
        this.setState({multimedia: result.specific_post})
    })

    });

    // Top News

    fetch('https://api.arynews.tv/content/appPakistan.txt').then((resp) => {

    resp.json().then((result) => {
        console.log(result)
        this.setState({pakistan: result.specific_post})
    })

    });

    fetch('https://arynews.tv/api/jsonify.php?cat=must-read-2&count=4&post_type=post&tax=category').then((resp) => {

        resp.json().then((result) => {
            console.log(result)
            this.setState({topnews: result.specific_post})
        })
    
        });

      }

      
      render() {
          
    return (
        
        
        <div className="post_gallary_area theme3_bg mb40 padding-top-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-xl-5 white_bg nopaddinglr">
                    {this.state.multimedia ?
								this.state.multimedia.map((item, i)=>
                        <div className="single_post post_type6 border-radious7 xs-mb30 test">
                            <div className="post_img gradient1">
                                <div className="img_wrap">
                                
                                    <Link to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>
                                
                                        <img src={item.thumbnail} alt="big_img"/>
                                    </Link>
                               
                                </div>
                                <span className="tranding">
									<FontAwesome name="play"/>
								</span>
                            </div>
                            <div className="single_post_text">

                                <h3><Link className="line-clamp2" to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>{item.title}</Link></h3>
                                <div className="space-5"/>

                                {/* <p className="post-p">{item.excerpt}</p> */}

                                {/* <div className="space-20"/> */}
                                <div className="meta meta_separator1"><Link to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>{item.categories[1]}</Link>
                                    <Link to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>{item.date}</Link>
                                </div>
                            </div>
                        </div>
                                ): null }

                         <div className="padding15 border-radious5 sm-mt30">
                        {this.state.topnews ?
								this.state.topnews.map((item, i)=>
                                <div key={i} className="single_post type14 widgets_small">
                                    <div className="post_img">
                                        <div className="img_wrap">
                                            <Link to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>
                                                <img src={item.image} alt="thumb"/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="single_post_text">
                                        <h4 className="limit2"><Link to={item.permalink}>{item.title}</Link></h4>
                                        {/* <div className="meta4"><Link to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>{item.categories[1]}</Link></div> */}
                                        {i + 1 < posts.length ? <Fragment>
                                            <div className="space-5"/>
                                            <div className="border_black"/>
                                            <div className="space-15"/>
                                        </Fragment> : null}
                                    </div>
                                </div>
                            ): null }
                        </div>

                    </div>
                    <div className="col-md-4 col-lg-4">
                   
                        <div className="heading padding10 white_bg mb20 border-radious5">
                            <h3 className="widget-title margin0">Top News</h3>
                       
                        </div>
                {this.state.pakistan ?
								this.state.pakistan.map((item, i)=>
                                <div className="col-md-6 col-lg-6 twoLines">
                    <div className="single_post post_type3 xs-mb90 post_type15">
                        <div className="post_img border-radious5">
                            <Link to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>
                                <img src={item.image} alt="big_img"/>
                            </Link>
                            <span className="tranding border_tranding"><FontAwesome name='bolt'/></span>
                        </div>
                        <div className="single_post_text nopaddinglr">
                            <h4><Link className="line-clamp2 smalltext" to= {{pathname: "/" + item.permalink.replace("https://arynews.tv/","")}}>{item.title}</Link></h4>
                        </div>
                    </div>
                    </div>

                                ): null }
                </div>
              
                    
                    <div className="d-none d-lg-block col-lg-3 col-xl-3">
                    <div className="img_wrap">
                                    <Link to="/">
                                        <img src={eden} alt="col26"/>
                                    </Link>
                                </div>
                                <div className="space-5"/>
                                <div className="img_wrap">
                                    <Link to="/">
                                        <img src={Urdu} alt="col26"/>
                                    </Link>
                                </div>
                                <div className="space-5"/>
                                <div className="img_wrap">
                                    <Link to="/">
                                        <img src={App} alt="col26"/>
                                    </Link>
                                </div>
                                <div className="space-5"/>
                                <div className="img_wrap">
                                    <Link to="/">
                                        <img src={Live} alt="col26"/>
                                    </Link>
                                </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
}

export default PostGalleryTwo;