
import React, {Fragment, useState, Component} from 'react';
import FontAwesome from "../../components/uiStyle/FontAwesome";

import {Link, Route, useParams, useLocation} from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import WidgetTab from "../../components/WidgetTab";
import WidgetTrendingNews from "../../components/WidgetTrendingNews";
import NewsLetter from "../../components/NewsLetter";
import FollowUs from "../../components/FollowUs";
import NewsByCat from "../../components/NewsByCat";
import BannerSection from "../../components/BannerSection";
import Pagination from "react-js-pagination";
import MyLoader2 from "../../components/Loader2";
import ListCategory from "../../components/Listing";
import PreLoader1 from '../../components/Preloader';
import AdSense from 'react-adsense';
import {Bling as GPT} from "react-gpt";
import ArynewsListing from "../../components/ArynewsListing";
// images
import banner2 from "../../doc/img/bg/sidebar-1.png";
import mrec from "../../doc/img/images/eden.jpg";
import AppDwonload from "../../doc/img/images/App.webp";
import LiveBox from "../../doc/img/images/Live-box.webp";
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';

class CategoryPage extends React.Component {


    constructor(props) 
    {
        super(props);
        this.state = {
		     catname : this.props.match.params.catname,
             postsPerPage: 15,
          }
          
        //we need to bind 'this' using ES6(javascript framework)
        // this.fetchnews = this.fetchnews.bind(this);
      
        
    }

    // componentDidMount(){

        
    // }

    
 render(){
    return (
        <Fragment>

            <Helmet>
                {/* <title>{this.state.title}</title> */}
                {parse(`${this.state.yoastData}`)}
                {/* {parse(this.state.yoastData['yoast_head'])} */}
                </Helmet>
                
               
              

            {/* <BreadCrumb title="Business"/> */}
            <div className="archives padding-top-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            
                            <NewsByCat loader="true" category={this.props.match.params.catname}/>
                          
                            
                            <div className="row mt-3">
                                <div className="col-12">
                                
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          
                            <div className="mrec" >
                            <center>
                                <GPT
                                adUnitPath="/67551462/arynews_videos-home_300x250"
                                slotSize={[336, 280], [300, 250]}
                            />
                            </center>
                            </div>

                            <center>
                                    <a href="https://arynews.tv/mobile-apps/"> 
                                    
                                    <img className="mrec" src={AppDwonload}/> 
                                    </a>
                                    
                                    <a  href="https://live.arynews.tv/pk/"> 
                                    
                                        <img className="mrec" src={LiveBox}/>
                                    
                                    </a>
                                    </center>

                            {/* <WidgetTrendingNewsThree/> */}
                            <ArynewsListing category="must-read-2" count="4" heading="Latest News" />
                            {/* <ListCategory category={this.props.match.params.catname} count="4" heading="HAR LAMHA PURJOSH" /> */}
                            {/* <NewsLetter className="mb-0 news3bg padding20 white_bg border-radious5"/> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-70"/>
            <BannerSection/>
        </Fragment>
    );
}
};

export default CategoryPage;