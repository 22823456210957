import React, {Fragment, useState, Component} from 'react';
import FontAwesome from "../../components/uiStyle/FontAwesome";
import {Link, Route, useParams, useLocation} from "react-router-dom";
import MyLoader2 from "../../components/Loader";
import MyLoader3 from "../../components/Loader";
import fnewsImg2 from '../../doc/img/feature/feature2.jpg';
import fnewsImg3 from '../../doc/img/feature/feature3.jpg';
import fnewsImg4 from '../../doc/img/feature/feature4.jpg';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactLoading from "react-loading";
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';
import {Bling as GPT} from "react-gpt";



class NewsByCat extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
		    catname : this.props.category,
            posts: [],
            postsPerPage: 12,
            currentPage: 1,
            categoryName: '',
            visible: 9,
            page: 1,
            yoastData: '',
            isMobile: false
          }
          this.loadMore = this.loadMore.bind(this);
          global.loaderp2 = true;
          global.loading = true;
        //we need to bind 'this' using ES6(javascript framework)
        // this.fetchnews = this.fetchnews.bind(this);
      
        
        }

        loadMore(){
            
            global.loaderp2 = false;
            this.setState((prev)=> {
                return {
                    isLoadingP3 : true,
                    visible : prev.visible + 9,
                    page: this.state.page + 1,
                    
                };
        });
        fetch('https://videos.arynews.tv/api/jsonify.php?cat='+this.props.category+'&count=9&post_type=post&tax=category&page='+this.state.page).then((resp) => {

            resp.json().then((result) => {
                console.log(result)
                this.setState({
                    categorylist: this.state.categorylist.concat(result.specific_post),
                    isLoadingP3:false
                })
              

            })
            // setState({loading: false});
            });
      
            
            // window.scrollTo({
            //     top: 400,
            //     behavior: 'smooth'
            // });
        }

    

        componentDidMount()
             {

                
        fetch('https://videos.arynews.tv/api/jsonify.php?cat='+this.props.category+'&count=9&post_type=post&tax=category&page='+this.state.page).then((resp) => {

            resp.json().then((result) => {
                console.log(result)
                this.setState({
                    categorylist: result.specific_post,
                    page: this.state.page + 1,
                    categoryName: result.specific_post[0].cat_name,
                   
                })
               
              

            })
            // setState({loading: false});
            global.loading = false;
            global.loaderp2 = false;
            });
            
            fetch('https://videos.arynews.tv/index.php/wp-json/wp/v2/categories?slug='+this.state.catname).then((resp) => {

                resp.json().then((result) => {
                
                    this.setState({yoastData: result[0].yoast_head});
                    this.setState({seotitle: result[0].name});
                    // this.setState({title: result[0]['title']['rendered']});
                    // alert(this.state.title);
                   
                })
               
                });

        
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
            }
          
        
    componentDidUpdate()
    {
      
 
        
        if(this.props.category != this.state.catname){
          //  alert("componentDidUpdate");
          global.loaderp2 = true;
          global.loading = true;
        fetch('https://videos.arynews.tv/api/jsonify.php?cat='+this.props.category+'&count=9&post_type=post&tax=category&page=1').then((resp) => {

            resp.json().then((result) => {
                console.log(result)
                this.setState({
                    
                    categorylist: result.specific_post,
                    catname: this.props.category,
                    categoryName: result.specific_post[0].cat_name,
                    visible: 9,
                    page: 2
                })
              
               

            })
            global.loading = false;
            global.loaderp2 = false
            });
            
            fetch('https://videos.arynews.tv/index.php/wp-json/wp/v2/categories?slug='+this.props.category).then((resp) => {

                resp.json().then((result) => {
                
                    this.setState({yoastData: result[0].yoast_head});
                    this.setState({seotitle: result[0].name});
                    // this.setState({title: result[0]['title']['rendered']});
                    // alert(this.state.title);
                   
                })
               
                });

        
        window.scrollTo(0, 0);
    }

    

  }

    render(){
        // alert(this.state.categoryName);
        // alert(global.loading);
    return (
        
        
        

               
                         
                         
        
        <div className="row justify-content-center">   
                   <div className="categories_title">
            <h5>Category: {this.state.categoryName}</h5>
            <br/>
        </div>  


        
            <Helmet>
                <title>{this.state.seotitle + " | ARY NEWS"}</title>
                {parse(`${this.state.yoastData}`)}
                {/* {parse(this.state.yoastData['yoast_head'])} */}
                </Helmet>


               
                

            {global.loading == true ? <MyLoader3/> :
            <>
                {this.state.categorylist ? 
								this.state.categorylist.map((item, i)=>
               
                                
                            
                
                    
                <div key={i} className="col-lg-4">
                     <div className="single_post post_type3 xs-mb90 post_type15">
                                    <div className="latest post_img border-radious5">
                                        <Link  to= {{pathname: "/" + item.slugs}}> 
                                                <img src={item.image} />
                                        </Link>
                                    </div>
                                    <div className="single_post_text nopaddinglr paddingtb">
                                        <h4>
                                            <Link className="line-clamp2 smalltext" to= {{pathname: "/" + item.slugs}}>{item.title}</Link>
                                        </h4>
                                    </div>
                                </div>
                  
                </div>
               
              
         
                ): null }
            </>
             }
              {this.state.isLoadingP3 ? <MyLoader3/> :null }
            
           
           
                <div className="text-center mt-5 mb-5">
                    <button onClick={this.loadMore} type="button" className="btn btn-primary load-more"> Load More</button>
                </div>
        

                {/* <div className="text-center mt-5 mb-5">
                    <button onClick={this.increment} type="button" className="btn btn-primary load-more"> Next</button>
                    <button onClick={this.decrement} type="button" className="btn btn-primary load-more"> Previous</button>
                </div> */}
           
        </div>
    );
    }
};

export default NewsByCat;