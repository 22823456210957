import React, {useState, useEffect} from 'react';
import bannerImg from '../../doc/img/bg/banner1.png';
import {Link} from "react-router-dom";
import {Bling as GPT} from "react-gpt";

const BannerSection = ({className}) => {

    const [isMobile, setIsMobile] = useState(false);
    
    const handleResize = () => {

        if (window.innerWidth < 720) {
            setIsMobile(true)
            // alert(window.innerWidth);
        } else {
            setIsMobile(false)
        }
      }

    useEffect(() => {
        // const count = 0;
        
       if(isMobile == false){
        window.addEventListener("resize", handleResize())
     }
    })

    return (
        <div className={`${className ? className : 'padding5050 fourth_bg'}`}>
            <div className="container">
            <div className="row">
                    <div className="leaderboard col-lg-12 col-sm-12">
                    {/* <img src={lb}/> */}

                     {isMobile == true ? (
                        <>
                        <h1>Mobile</h1>
                        <GPT
                     adUnitPath="/67551462/Catfish-Videos-News-LB"
                     slotSize={[320, 100], [320, 50]}
                        />
                     </>

                     ) : 
                     <>
                        <h1>Desktop</h1>
                     <GPT
                     adUnitPath="/67551462/Catfish-Videos-News-LB"
                     slotSize={[728, 90], [468, 60], [970, 90]}
                        />
                    </>
                    }

                    </div>
                </div>
                </div>
        </div>
    );
};

export default BannerSection;