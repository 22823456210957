import React, {Fragment, useState, useEffect} from 'react';
import PostGalleryTwo from "../../components/PostGalleryTwo";
import TrendingNewsTwo from "../../components/TrendingNewsTwo";
import Fullwidth from "../../components/Fullwidth";
import FeatureNewsTwo from "../../components/FeatureNewsTwo";
import BusinessNewsTwo from "../../components/BusinessNewsTwo";
import MostViewTwo from "../../components/MostViewTwo";
import banner2 from "../../doc/img/bg/sidebar-1.png";
import banner3 from "../../doc/img/bg/banner1.png";
import adsky from "../../doc/img/images/300x600.jpg";
import adlb from "../../doc/img/images/728x90.png";
import WidgetFinance from "../../components/WidgetFinance";
import VideoNews from "../../components/VideoNews";
import LatestNews from "../../components/LatestNews";
import WidgetTabTwo from "../../components/WidgetTabTwo";
import Opinion from "../../components/Opinion";
import CustomWidget from "../../components/CustomWidget";
import ListCategory from "../../components/Listing";
import Whatsnew from "../../components/Whatsnew";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";
import FollowUs from "../../components/FollowUs";
import MostViewThree from "../../components/MostViewThree";
import Sports from "../../components/Sports";
import International from "../../components/International";
import FeatureNews from "../../components/FeatureNews";
import { Advertisement } from 'semantic-ui-react'
import PostCarousel from "../../components/PostCarousel";
import {Bling as GPT} from "react-gpt";
import ArynewsListing from "../../components/ArynewsListing";
import AppDwonload from "../../doc/img/images/App.webp";
import LiveBox from "../../doc/img/images/Live-box.webp";
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';



const HomePageTwo = () => {

    const [yoast, setYoast] = useState('');
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {

        if (window.innerWidth < 720) {
            setIsMobile(true)
            // alert(window.innerWidth);
        } else {
            setIsMobile(false)
        }
      }

    useEffect(() => {
        // const count = 0;
        
       if(isMobile == false){
        window.addEventListener("resize", handleResize())
     }
        if(yoast == '') 
         fetch('https://videos.arynews.tv/index.php/wp-json/wp/v2/pages?slug=home-v2').then((resp) => {

        resp.json().then((result) => {
          
            setYoast(result[0].yoast_head)
            console.log(result)
          
     
  })

  }) })
        
    return (
        <Fragment>
            
            <Helmet>
                <title>ARY NEWS Videos</title>
                {parse(`${yoast}`)}
            </Helmet>

            
            

            <div className="container">
            <div className="row">
                    <div className="leaderboard col-lg-12">
                    {/* <img src={lb}/> */}

                     {isMobile == true ? (
                     <GPT
                        adUnitPath="/67551462/arynews_videos-home_300x250"
                        slotSize={[300, 250], [336, 280]}
                     />
                     ) : <GPT
                     adUnitPath="/67551462/arynews_videos_728x90"
                     slotSize={[728, 90], [468, 60], [970, 90], [970, 250]}
                        />}

                    </div>
                </div>
                </div>
                
                <div className="total3 mb30">
                    <div className="container nopaddinglr">
                 
                        <div className="row">
                            <div className="col-md-12 col-lg-8">
                            {isMobile == false ? (
                                <>
                                <Fullwidth fwcategory="recent" count="6" columns="4" heading="LATEST VIDEOS"/>
                                <Fullwidth fwcategory="hot-shots-2" count="8" columns="3" heading="HOT SHOTS"/>
                                <Fullwidth fwcategory="headlines" count="8" columns="3" heading="HEADLINES"/>

                                </>                            
                            ) :
                            <>
                            <LatestNews category="recent"/>
                            <ListCategory category="hot-shots-2" count="5" heading="Hot Shots" />  
                            
                                <div className="heading padding20 white_bg mb20 border-radious5">
                                <h3 className="widget-title margin0">Multimedia Stories</h3>
                                </div>
                            
                            <PostCarousel className=""/>

                            </>  
                            }   
                            
                            
                            </div>
                            <div className="col-lg-4">
                                <div className="row justify-content-center">
                                    <div className="col-md-12 col-lg-12">
                                    <center>
                                    <div className="mrec">
                                    
                                    <GPT
                                        adUnitPath="/67551462/arynews_videos-home_300x250"
                                        slotSize={[336, 280], [300, 250]}
                                    />
                                   
                                    </div>
                                    </center>
                                    {/* <ins className='adsbygoogle'
                                    style={{ display: 'block' }}
                                    data-ad-client='div-gpt-ad-1630936077052-0'
                                    data-ad-slot='67551462'
                                    data-ad-format='auto' /> */}
                                     <center>
                                     <a  href="https://arynews.tv/mobile-apps/"> 
                                    
                                    <img className="mrec" src={AppDwonload}/> 
                                    </a>
                                    
                                    <a  href="https://live.arynews.tv/pk/"> 
                                    
                                        <img className="mrec" src={LiveBox}/>
                                    
                                    </a>
                                    </center>
                                    {isMobile == false ? (
                                        <>
                                    <CustomWidget category="bakhabar-savera" heading="Bakhabar Savera"/>
                                    <ArynewsListing category="must-read-2" count="5" heading="Latest News" />
                                    </>
                                    ) :
                                    <ArynewsListing category="must-read-2" count="5" heading="Latest News" />
                                    }
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {isMobile == false ? (    
            <Fullwidth fwcategory="ary-special-3" count="8" columns="3" heading="ARY SPECIAL"/>
            ):
            <LatestNews/> 
                }
            <FeatureNews/>
        </Fragment>
    );
};

export default HomePageTwo;