import React from 'react';
import {Link} from "react-router-dom";

const quick_links = [
    {
        name: 'Facebook',
        link: 'https://www.facebook.com/arynewsasia/'
    },
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/arynewstv/'
    },
    {
        name: 'Twitter',
        link: 'https://twitter.com/ARYNEWSOFFICIAL'
    },
];

const FooterCopyright = () => {
    return (
        <div className="copyright">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <p>ARY NEWS&copy; Copyright 2021, All Rights Reserved</p>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="copyright_menus text-right">
                            <div className="language"/>
                            <div className="copyright_menu inline">
                                <ul>
                                    {quick_links.map((item, i) => (
                                        <li key={i}><a href={item.link}>{item.name}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterCopyright;