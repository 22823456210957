import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader2 = (props) => (
  <ContentLoader 
  speed={2}
  width={1240}
  height={400}
  viewBox="0 0 1240 400"
  backgroundColor="#8a8a8a"
  foregroundColor="#ecebeb"
    {...props}
  >
   
   <rect x="6" y="28" rx="3" ry="3" width="70%" height="28" /> 
    <rect x="7" y="28" rx="3" ry="3" width="70%" height="4" /> 
    <rect x="6" y="69" rx="3" ry="3" width="70%" height="252" /> 


  </ContentLoader>
)

export default MyLoader2