import React, {useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import {NavLink} from "react-router-dom";
import SidebarMenu from "../SidebarMenu";
import logo from '../../doc/img/logo/logo.webp';
import lb from '../../doc/img/images/lb.jpg';
import tempIcon from "../../doc/img/icon/temp.png";
import { render } from '@testing-library/react';
import AdSense from 'react-adsense';
import {Bling as GPT} from "react-gpt";

const LogoAreaTwo = () => {

    const [search, setSearch] = useState('');
    const [sideShow, setSideShow] = useState(false);
    const [slideShow, setSlideShow] = useState(false);
    const submitHandler = e => {
        e.preventDefault();
        setSearch('')
    };
    const [isMobile, setIsMobile] = useState(false);
    //  const [count, setCount] = useState(0);
    // const [count, setCount] = useState(0);
      const [nav, setNav] = React.useState(0);
    // const [state, setState] = useState(initialState);
    
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
      }

    useEffect(() => {
        // const count = 0;
       if(isMobile == false){
        window.addEventListener("resize", handleResize())
    }
        if(nav == 0) 
         fetch('https://videos.arynews.tv/api/newsmenujson.php').then((resp) => {

        resp.json().then((result) => {
          
            setNav(result)
            console.log(result)
          
     
  })


  }) 

})
        

    

    
 

    return (
        <div className="logo_area">
            <div className="white_bg">
            <div className="container">
                <div className="row main">
                    <div className="col-lg-3 align-self-center">
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="logo"/>
                            </Link>
                            
                        </div>
                    </div>
                    
                    <div className="col-lg-9 align-self-center">
                        <nav className="navbar navbar-expand-lg">
                            <div className="site-nav-inner test">
                                <button className="navbar-toggler" onClick={() => setSideShow(true)}>
                                    <FontAwesome name="bars"/>
                                </button>
                                
                                               
                                <div id="navbarSupportedContent"
                                     className="collapse navbar-collapse navbar-responsive-collapse">
                                    <ul className="nav navbar-nav" id="scroll">
                                        <li className="nav-item"> <a href="https://arynews.tv/"> Home </a> </li>
                                        {nav ? nav.map((item, i) => (
                                            <li key={i}
                                                className={`
                                                ${item.menu_item_parent ? 'dropdown' : ''}
                                                nav-item`}>
                                                {item.menu_item_parent == 0 & item.title != 'TV Shows' && item.title != 'Home' ? <Link 
                                                                   to= {{pathname: "/" + item.url.replace("https://videos.arynews.tv/","")}}
                                                                       className=""
                                                                       data-toggle="">{item.title}
                                                        <FontAwesome
                                                            name={item.icon}/></Link>
                                                    : null
                                                    // <NavLink to={item.link} className="menu-dropdown"
                                                    //            data-toggle="dropdown">{item.post_title} <FontAwesome
                                                    //     name={item.icon}/></NavLink>
                                                        }

                                                
                                            </li>
                                        )): null }

                                                        
                                                <li>

                                                <NavLink to="/" onClick={e => e.preventDefault()} 
                                                                       className="menu-dropdown"
                                                                       data-toggle="dropdown">TV Shows
                                                </NavLink>

                                                <ul className="dropdown-menu" role="menu">
                                                    
                                                {nav ? nav.slice(7).map((item, i) => (
                                                      
                                                       <li key={i}
                                                            className={`${item.child ? 'dropdown-submenu' : null}
                                                    `}>
                                                            {item.ID !== 468244 ?

                                                        <Link  to= {{pathname: "/" + item.url.replace("https://videos.arynews.tv/","")}}>
                                                            {item.title} 
                                                        </Link>
                                                                // <NavLink className={item.menu_item_parent} onClick={e => e.preventDefault()}
                                                                // to= {{pathname: "/" + item.url.replace("https://arynews.tv/","")}}>{item.title}</NavLink>
                                                                : null }
                                                            
                                                        </li>
                                                    )): null }
                                                </ul>
                                                </li>
                                             
                                    </ul>
                                </div>
                                <SidebarMenu className="themeBlue" sideShow={sideShow} setSideShow={setSideShow} menus={setNav}/>
                            </div>
                        </nav>
                    </div>
                    
                </div>
                
               
            
            </div>
            </div>
            
        </div>
    );
};

export default LogoAreaTwo;