import React from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';

const NewsLetter = ({className, input_white, titleClass}) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);

  if(onSubmit){
  // alert(watch("email")); // watch input value by passing the name of i
}

  
    return (
        <div className={`box widget news_letter mb30 ${className ? className : ''}`}>
            <h2 className={`widget-title ${titleClass}`}>News Letter</h2>
            <p>Your email address will not be this published. Required fields are News Today.</p>
            <div className="space-20"/>
            <div className="">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* register your input into the hook by invoking the "register" function */}
              <input defaultValue="" className={`signup ${input_white ? 'white_bg' : ''}`} placeholder="Enter your name" {...register("name")} />
              <input defaultValue="" className={`signup ${input_white ? 'white_bg' : ''}`} placeholder="Enter your email" {...register("email")} />
              <input defaultValue="" className={`signup ${input_white ? 'white_bg' : ''}`} placeholder="Enter your phone" {...register("phone")} />
              
              {/* include validation with required or other standard HTML validation rules */}
              {/* <input {...register("exampleRequired", { required: true })} /> */}
              {/* errors will return when field validation fails  */}
              {errors.exampleRequired && <span>This field is required</span>}
              
              <input type="submit" className="cbtn" />
            </form>
                <div className="space-10"/>
                <p>We hate spam as much as you do</p>
            </div>
        </div>
    );
};

export default NewsLetter;
