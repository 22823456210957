import React, {Fragment, useState} from 'react';
import FontAwesome from "../../components/uiStyle/FontAwesome";
import {Link, useLocation} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";
import BannerSection from "../../components/BannerSection";
import Whatsnew from "../../components/Whatsnew";
import FollowUs from "../../components/FollowUs";
import NewsByCat from "../../components/NewsByCat";
import WidgetTrendingNewsThree from "../../components/WidgetTrendingNewsThree";
import PostOnePagination from "../../components/PostOnePagination";
import OurBlogSectionTwo from "../../components/OurBlogSectionTwo";
import BlogComment from "../../components/BlogComment";
import ModalVideo from 'react-modal-video';
import ListCategory from "../../components/Listing";
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';
import ReactPlayer from "react-player";
import Dailymotion from 'react-dailymotion';
import MyLoader2 from "../../components/Loader2";
import ArynewsListing from "../../components/ArynewsListing";
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
    PinterestIcon
  } from "react-share";

  import {Bling as GPT} from "react-gpt";
  import {StickyShareButtons} from 'sharethis-reactjs';

// images
import sd_banner_img from '../../doc/img/bg/sidebar-1.png';
import single_post1 from "../../doc/img/blog/single_post1.jpg";
import author2 from "../../doc/img/author/author2.png";
import smail1 from "../../doc/img/blog/smail1.jpg";
import big1 from "../../doc/img/blog/big1.jpg";
import big2 from "../../doc/img/blog/big2.jpg";
import mrec from "../../doc/img/images/eden.jpg";
import AppDwonload from "../../doc/img/images/App.webp";
import LiveBox from "../../doc/img/images/Live-box.webp";


class CategoryPage2 extends React.Component {

    constructor(props) 
    {
        
        super(props);
        this.state = {
		     catname : this.props.match.params.catname,
             postsPerPage: 15,
          }
          
    }

    componentWillMount(){
        
        // alert(window.innerWidth);
        if (window.innerWidth < 720) {
            this.setState({isMobile: true});
        } 
    }


    //  componentDidMount(){

    //     alert(window.innerWidth);
        
    // }


    render(){
     return (

        <Fragment>
        
        <div className="container">
            <div className="row">
                    <div className="leaderboard col-lg-12">
                    {/* <img src={lb}/> */}

                     {this.state.isMobile == true ? (
                        <> 
                        <GPT
                                adUnitPath="/67551462/arynews_videos-home_300x250"
                                slotSize={[336, 280], [300, 250]}
                        />
                     </>

                     ) : 
                     <>
                     <GPT
                     adUnitPath="/67551462/arynews_videos_728x90"
                     slotSize={[728, 90], [468, 60], [970, 90], [970, 250]}
                        />
                    </>
                    }

                    </div>
                </div>
                </div>
           {/* <BreadCrumb title="Business"/> */}
            <div className="archives padding-top-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            
                            <NewsByCat loader="true" category={this.props.match.params.catname}/>
                          
                            
                            <div className="row mt-3">
                                <div className="col-12">
                                
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          
                            <div className="mrec" >
                            <center>
                                <GPT
                                adUnitPath="/67551462/arynews_videos-home_300x250"
                                slotSize={[336, 280], [300, 250]}
                            />
                            </center>
                            </div>

                            <center>
                                    <a href="https://arynews.tv/mobile-apps/"> 
                                    
                                    <img className="mrec" src={AppDwonload}/> 
                                    </a>
                                    
                                    <a  href="https://live.arynews.tv/pk/"> 
                                    
                                        <img className="mrec" src={LiveBox}/>
                                    
                                    </a>
                                    </center>

                            {/* <WidgetTrendingNewsThree/> */}
                            <ArynewsListing category="must-read-2" count="4" heading="Latest News" />
                            {/* <ListCategory category={this.props.match.params.catname} count="4" heading="HAR LAMHA PURJOSH" /> */}
                            {/* <NewsLetter className="mb-0 news3bg padding20 white_bg border-radious5"/> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-70"/>
            <div className="container">
            <div className="row">
                    <div className="leaderboard col-lg-12">
                    {/* <img src={lb}/> */}

                     {this.state.isMobile == true ? (
                         <>
                         {/* <h1>Mobile</h1> */}
                         <GPT
                      adUnitPath="/67551462/Catfish-Videos-News-LB"
                      slotSize={[320, 100], [320, 50]}
                         />
                      </>
 
                      ) : 
                      <>
                         {/* <h1>Desktop</h1> */}
                      <GPT
                      adUnitPath="/67551462/Catfish-Videos-News-LB"
                      slotSize={[728, 90], [468, 60], [970, 90]}
                         />
                     </>
                    }

                    </div>
                </div>
                </div>
        </Fragment>
    )
};
}
export default CategoryPage2;