import React from 'react';
import {Link, Route} from "react-router-dom";
import {StickyShareButtons} from 'sharethis-reactjs';
import {InlineShareButtons} from 'sharethis-reactjs';
import MyLoader2 from "../../components/Loader2";
import Share from "../../components/Share";

class PostOnePagination extends React.Component {

    constructor() 
    {
        super();
        this.state = {
			isShow: false,
            // urlnew: this.props.url
          }
        //we need to bind 'this' using ES6(javascript framework)
        // this.fetchnews = this.fetchnews.bind(this);
      
          
    }


    componentDidMount()
    {
 
        // alert(this.props.category);
        // window.location.reload(true);
        // window.location.reload(false);
        window.scrollTo(0, 0);
    // alert(this.props.category);
//    alert(this.props.match.params.slug);
      // Multimedia
fetch('https://videos.arynews.tv/api/jsonify.php?cat='+this.props.category+'&count=4&post_type=post&tax=category&orderby=random').then((resp) => {

  resp.json().then((result) => {
      console.log(result)
      this.setState({related: result.specific_post, isShow: true})

    //   window.scrollTo(0, 0);
     
  })

  });
  
  

  }

 

    render(){
      
    return (
        <div className="next_prev">
             
             <h3>Related Videos</h3>
             <div className="space-20"/>
            <div className="row">

               {this.state.isShow == false ? <MyLoader2/> : 
								this.state.related.map((item, i)=>
                      
                                <>
                                {/* <Share/> */}
                                <div  key={i} className="col-lg-6 align-self-center">
                    <div className='next_prv_single border_left3'>
                    <Link to= {{pathname: "/" + item.slugs}}> {item.title}</Link>
                    
                        {/* <h3><Link  to= {{pathname: "/home-two/post2/" + item.permalink.replace("https://arynews.tv/","")}}>{item.title}</Link></h3> */}
                    </div>
                    <div className="space-20"/>
                    </div>
                       </>
                       
                   )}
                
                
              

            </div>
        </div>

        
        
    )
}}

export default PostOnePagination;