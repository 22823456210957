import React from 'react';
import {Link, Route} from "react-router-dom";

import {StickyShareButtons} from 'sharethis-reactjs';
import {InlineShareButtons} from 'sharethis-reactjs';

class Share extends React.Component {

    constructor() 
    {
        super();
        this.state = {
			relaod: false,
          }
        //we need to bind 'this' using ES6(javascript framework)
        // this.fetchnews = this.fetchnews.bind(this);
      
          
    }

    refreshPage = () => {
        this.setState(
          {reload: true},
          () => this.setState({reload: false})
        )
      }

    componentWillMount(){

        // alert(this.props.url);
    }

//     componentDidMount()
//     {
 
//         // alert(this.props.category);
//         // window.location.reload(true);
//         // window.location.reload(false);
//         window.scrollTo(0, 0);
//     // alert(this.props.category);
// //    alert(this.props.match.params.slug);
//       // Multimedia
// fetch('https://videos.arynews.tv/api/jsonify.php?cat='+this.props.category+'&count=4&post_type=post&tax=category&orderby=random').then((resp) => {

//   resp.json().then((result) => {
//       console.log(result)
//       this.setState({related: result.specific_post})

//     //   window.scrollTo(0, 0);
     
//   })

//   });
  
  

//   }

 

    render(){
      
    return (
       

        <StickyShareButtons
                                config={{
                                alignment: 'left',    // alignment of buttons (left, right)
                                color: 'social',      // set the color of buttons (social, white)
                                enabled: true,        // show/hide buttons (true, false)
                                font_size: 16,        // font size for the buttons
                                hide_desktop: false,  // hide buttons on desktop (true, false)
                                labels: 'counts',     // button labels (cta, counts, null)
                                language: 'en',       // which language to use (see LANGUAGES)
                                min_count: 0,         // hide react counts less than min_count (INTEGER)
                                networks: [           // which networks to include (see SHARING NETWORKS)
                                'whatsapp',
                                'facebook',
                                'twitter',
                                'linkedin',
                                'email'
                                ],
                                padding: 12,          // padding within buttons (INTEGER)
                                radius: 4,            // the corner radius on each button (INTEGER)
                                show_total: true,     // show/hide the total share count (true, false)
                                show_mobile: true,    // show/hide the buttons on mobile (true, false)
                                show_toggle: true,    // show/hide the toggle buttons (true, false)
                                size: 48,             // the size of each button (INTEGER)
                                top: 160,             // offset in pixels from the top of the page
                    
                                // OPTIONAL PARAMETERS
                                //  url: this.props.url // (defaults to current url)
                                // image: 'item.image',  // (defaults to og:image or twitter:image)
                                // description: 'custom text',       // (defaults to og:description or twitter:description)
                                // title: 'custom title',            // (defaults to og:title or twitter:title)
                                // message: 'custom email text',     // (only for email sharing)
                                // subject: 'custom email subject',  // (only for email sharing)
                                // username: 'custom twitter handle' // (only for twitter sharing)
                    
                            }}
                        />


        
    )
}}

export default Share;