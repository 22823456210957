import React, {Fragment, Component} from 'react';
import {Link, Route} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import big_img from '../../doc/img/header/sider-top3.jpg';
import col26 from '../../doc/img/header/widget/col26.jpg';
import col21 from '../../doc/img/header/widget/col21.jpg';
import col22 from '../../doc/img/header/widget/col22.jpg';
import col23 from '../../doc/img/header/widget/col23.jpg';
import col24 from '../../doc/img/header/widget/col24.jpg';
import col25 from '../../doc/img/header/widget/col25.jpg';
// import PostTwoHTwoPage from "../../components/PostTwoHTwoPage";
import App from '../../doc/img/images/App.png';
import Live from '../../doc/img/images/Live-box.png';
import eden from '../../doc/img/images/eden.jpg';
import Urdu from '../../doc/img/images/urdu-box.png';
import Loader from 'react-loader-spinner';
// import { Facebook } from 'react-content-loader';
import MyLoader from "../../components/Loader";



const StyleSheet = {
    container: {
      border: "0px solid rgba(255, 255, 255, 1)",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)",
      borderRadius: "4px",
      backgroundColor: "white",
      display: "flex",
      padding: "16px",
      width: "200px"
    },
    circle: {
      height: "56px",
      width: "56px",
      borderRadius: "50%"
    },
    line: {
      width: "96px",
      height: "8px",
      alignSelf: "center",
      marginLeft: "16px",
      borderRadius: "8px"
    }
  };

const posts = [
    {
        image: col21,
        title: 'The city with highest quality of life in world.',
        category: 'TECHNOLOGY',
    },
    {
        image: col22,
        title: 'Fire shows that will improve your…',
        category: 'TECHNOLOGY',
    },
    {
        image: col23,
        title: 'Mutul fund mark from down up to 15%.',
        category: 'TECHNOLOGY',
    },
    {
        image: col24,
        title: 'Danny meyer’s form latest restaurantes…',
        category: 'TECHNOLOGY',
    },
    {
        image: col25,
        title: 'Wright begins in rehab assignment at the..',
        category: 'TECHNOLOGY',
    },
];


class Fullwidth extends React.Component {

    constructor() 
    {
        super();
        this.state = {
			isShow: false,
            isLoadingP2: true
          }
        //we need to bind 'this' using ES6(javascript framework)
        // this.fetchnews = this.fetchnews.bind(this);
      
  
      }
    replaceStr(string){
        // string.replace("https://arynews.tv/en/","");
        string.replace("https://arynews.tv/","");
        string.replace("/","");
        return string;

    }

    componentDidMount()
      {

        let url = '';
      {
        this.props.fwcategory == 'recent' ?  
      url = 'https://videos.arynews.tv/api/recentVid.php?count='+this.props.count

    //   For Text File:  url = https://api.arynews.tv/readtxt.php?cat=appScience&count='+this.props.count
      :
      url = 'https://videos.arynews.tv/api/jsonify.php?cat='+this.props.fwcategory+'&count='+this.props.count+'&post_type=post&tax=category'
        }

        // Multimedia
    fetch(url).then((resp) => {

    resp.json().then((result) => {
        console.log(result)
        this.setState({fwcategory: result.specific_post, isLoadingP2: false})
    })

    });

    }

      
      render() {
        const {isLoading } = this.state;
    return (
        
        
    <div className="post_gallary_area theme3_bg mb40">
        <div className="container">
            <div className="row">
                <div className="col-12 nopaddinglr">
                    <div className="heading padding20 white_bg mb20 border-radious5">
                        <h3 className="widget-title margin0">{this.props.heading}</h3>
                    </div>
                </div>
            </div>

            <div className="row">
                    
                <div className="col-md-12 col-lg-12 nopaddinglr">
                    {this.state.isLoadingP2 ? <MyLoader/> :
                    <>
                    {this.state.fwcategory ?
                                    this.state.fwcategory.map((item, i)=>
                                    
                                    
                        <div key={item.ID} className={`col-md-${this.props.columns} twoLines nopaddingl`}>
                            <div className="single_post post_type3 xs-mb90 post_type15">
                                <div className="latest post_img border-radious5">
                                    <Link  to= {{pathname: "/" + item.slugs}}> 
                                            <img src={item.image} />
                                    </Link>
                                </div>
                                <div className="single_post_text nopaddinglr paddingtb">
                                    <h4>
                                        <Link className="line-clamp2 smalltext" to= {{pathname: "/" + item.slugs}}>{item.title}</Link>
                                    </h4>
                                </div>
                            </div>
                        
                        </div>
                                
                    ): null }
                    </>
                    }
                </div>
            </div>
        </div>
    </div>
    );
};
}

export default Fullwidth;