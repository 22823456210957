import React, {useState} from 'react';
import Heading from "../uiStyle/Heading";
import Swiper from 'react-id-swiper';

import fnewsImg2 from '../../doc/img/feature/feature2.jpg';
import fnewsImg3 from '../../doc/img/feature/feature3.jpg';
import fnewsImg4 from '../../doc/img/feature/feature4.jpg';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

const news = [
    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/BKS-web.jpg',
        link: 'https://videos.arynews.tv/category/bakhabar-savera/',
        
    },
    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/Off-the-record-1-min.jpg',
        link: 'https://videos.arynews.tv/category/off-the-record-with-kashif-abbasi/',
    },

    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/the-reporters-new-min.jpg',
        link: 'https://videos.arynews.tv/category/the-reporters/',
        
    },
    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/powerplay-min.jpg',
        link: 'https://videos.arynews.tv/category/power-play/',
    },
    
    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/11-hour-min.jpg',
        link: 'https://videos.arynews.tv/category/11th-hour-with-waseem-badami/',
        
    },
    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/sawal-ye-hai-min.jpg',
        link: 'https://videos.arynews.tv/category/sawal-yeh-hai-with-maria-memon/',
    },

    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/Atiraz-hai-min.jpg',
        link: 'https://videos.arynews.tv/category/aiteraz-hai-adil-abbasi/',
        
    },
    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/sareaam-min.jpg',
        link: 'https://videos.arynews.tv/category/sare-e-aam-with-iqrar-ul-hasan/',
    },

    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/sports-room-min.jpg',
        link: 'https://videos.arynews.tv/category/sports-room-2/',
        
    },
    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/Zimmedar-kon-min.jpg',
        link: 'https://videos.arynews.tv/category/zimmedar-kaun/',
    },

    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/CMW-1-min.jpg',
        link: 'https://videos.arynews.tv/category/cmw/',
        
    },
    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/hamare-mehman-min.jpg',
        link: 'https://videos.arynews.tv/category/humarey-mehman/',
    },

    {
        image: 'https://arynews.tv/wp-content/uploads/2021/05/Hoshyarian-web-min.png',
        link: 'https://videos.arynews.tv/category/hoshyarian/',
    },


    
    
];



const FeatureNews = ({className}) => {
    const [swiper, setSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,
        breakpoints: {
            1024: {
                slidesPerView: 5,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            }
        }
    };
    return (
        <div className={`feature_carousel_area mb40 ${className ? className : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading title="Feature News"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {/*CAROUSEL START*/}
                        <div className="feature_carousel nav_style1">
                            <Swiper getSwiper={setSwiper} {...params}>
                                {news.map((item, i) => (
                                    <div key={i} className="single_post post_type6 post_type7">
                                        <div className="post_img gradient1">
                                            <a href={item.link}><img src={item.image} alt="thumb"/></a>
                                        </div>
                                     </div>
                                ))}
                            </Swiper>
                            <div className="navBtns">
                                <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left"/></div>
                                <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right"/></div>
                            </div>
                        </div>
                        {/*CAROUSEL END*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureNews;