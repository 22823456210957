import React, {useState, useEffect} from 'react';
import Heading from "../uiStyle/Heading";
import Swiper from 'react-id-swiper';

import fnewsImg2 from '../../doc/img/feature/feature2.jpg';
import fnewsImg3 from '../../doc/img/feature/feature3.jpg';
import fnewsImg4 from '../../doc/img/feature/feature4.jpg';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

const LatestNews = ({className}) => {
    const [swiper, setSwiper] = useState(null);
    const [news, setNews] = useState(null);
    // const [recent] = props;
    const [ispostSLoader, setPostLoader] = useState(false); 

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

   
    const params = {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,
        breakpoints: {
            1024: {
                slidesPerView: 5,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            }
        }
    };

    useEffect(() => {
       
        if(news == null){
        // alert(recent.category);
        fetch('https://videos.arynews.tv/api/jsonify.php?cat=hot-shots-2&count=12&post_type=post&tax=category').then((resp) => {

        resp.json().then((result) => {
          
            setNews(result)
            console.log(result)
            setPostLoader(true);
          
     
  })


  }) 
    }
        
    })

    return (
        <div className={`feature_carousel_area mb10 ${className ? className : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading title="Latest Videos"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {/*CAROUSEL START*/}
                        {ispostSLoader == true && (
                        <div className="feature_carousel nav_style1">
                            <Swiper getSwiper={setSwiper} {...params}>
                                {news.specific_post.map((item, i) => (
                                    <div className="single_post post_type3 xs-mb90 post_type15">
                                    <div className="latest post_img border-radious5">
                                        <Link  to= {{pathname: "/" + item.slugs}}> 
                                                <img src={item.image} />
                                        </Link>
                                    </div>
                                    <div className="single_post_text nopaddinglr paddingtb">
                                        <h4>
                                            <Link className="line-clamp2 smalltext" to= {{pathname: "/" + item.slugs}}>{item.title}</Link>
                                        </h4>
                                    </div>
                                </div>
                                ))}
                            </Swiper>
                            <div className="navBtns">
                                <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left"/></div>
                                <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right"/></div>
                            </div>
                        </div>
                        )}
                        {/*CAROUSEL END*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LatestNews;